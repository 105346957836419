import React from 'react'
import styled from 'styled-components'
import Vivus from 'vivus'
import { InView } from 'react-intersection-observer'

import Section from '../../components/Section'
import map from './map.svg'

import Flower from '../../components/Flower'

const TravelSectionContainer = styled.div`
    p + h3 {
        margin-top: 3em;
    }

    p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    ul, ol {
        margin-right: 2em;
        padding-top: 1em;
        margin-bottom: 1em;
        text-align: justify;
    }

    a {
        text-decoration: underline;
    }


    #animated-map {
        margin-top: 2em;
        max-width: 350px;
        width: 100%;
        height: auto;
    }

    @media (min-width: 1024px) {
        font-size: 24px;

        #animated-map {
            max-width: 500px;
            width: 100%;
            height: auto;
        }
    }
`

class TravelSection extends React.Component {
    constructor(props) {
        super(props)
        this.refRoot = React.createRef()
        this.refMap = React.createRef()

        const parts = 200
        this.steps = Array.from(Array(parts).keys()).map((x, i) => {
            return (1 / parts) * (i + 1)
        })
    }

    componentDidMount() {
        this.mapInstance = new Vivus(this.refMap.current, {
            type: 'scenario',
            delay: 1,
            duration: 1000,
            start: 'manual',
            animTimingFunction: Vivus.EASE_IN,
            onReady: () => {
                this.mapInstance.finish()
            }
            // reverseStack: true
        })
        // this.mapInstance.setFrameProgress(0)
    }

    animateMap() {
        this.mapInstance.play()
    }

    render() {
        return (
            <>
                <Section ref={this.refRoot} id="travel" centered>
                    <TravelSectionContainer>
                        <h2>
                            <Flower variant="yellow" />
                            Getting to La Pescaia
                            <Flower variant="yellow" flipped />
                        </h2>

                        <p style={{ textAlign: 'justify' }}>
                            <a href="https://goo.gl/maps/EFJmTXsvngBZCUkU6">
                                La Pescaia
                            </a>{' '}
                            is located midway between Rome and Florence in
                            southern Tuscany, Italy. We recommend flying into
                            Rome Fiumicino (FCO), as it has the only direct
                            flights to/from the States. It is also an option to
                            fly into Florence (1hr 50min away) or Pisa (1hr
                            20min away), though no direct flights are available
                            to/from the States.
                        </p>

                        <InView
                            as="div"
                            root={this.refRoot.current}
                            onChange={(inView, entry) => {
                                const isPast = entry.boundingClientRect.y < 0

                                if (inView && !isPast) {
                                    this.mapInstance.setFrameProgress(
                                        entry.intersectionRatio
                                    )
                                }
                            }}
                            threshold={this.steps}
                            triggerOnce={false}
                        >
                            <object
                                ref={this.refMap}
                                id="animated-map"
                                type="image/svg+xml"
                                data={map}
                                width={400}
                                height={423.641}
                            />
                        </InView>

                        <h3>from Roma</h3>
                        <p style={{ textAlign: 'justify' }}>
                            <a href="https://goo.gl/maps/EFJmTXsvngBZCUkU6">
                                La Pescaia
                            </a>{' '}
                            is a 2 hour drive from FCO where there are many
                            rental car options available. If you are planning to
                            visit Rome prior to arriving at La Pescaia, we
                            recommend taking public transportation or a taxi
                            into the central city and renting a car on your way
                            out of town (driving/parking in Rome can be
                            challenging). A good option is to rent a car from
                            the Hertz in Vatican City.
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                            Trains are available from Roma Termini station to
                            Grosseto (2hrs 20min). From Grosseto, guests can
                            either rent a car or take a taxi to La Pescaia (~20
                            mins away). There are numerous car rental options
                            close to the station.
                        </p>

                        <h3>from Florence</h3>
                        <p style={{ textAlign: 'justify' }}>
                            If you choose to fly into Florence, your best option
                            is to rent a car and drive to La Pescaia. The resort
                            is 1 hour and 50 mins drive from Florence.
                        </p>

                        <h3>from Pisa</h3>
                        <p style={{ textAlign: 'justify' }}>
                            If you choose to fly into Pisa, your best option is
                            to rent a car and drive to La Pescaia. The resort is
                            1 hour and 20 mins drive from Pisa.
                        </p>
                    </TravelSectionContainer>
                </Section>

                <br /><br /><br /><br />

                <Section id="faq" centered>
                    <TravelSectionContainer>
                        <h2>
                            <Flower variant="yellow" />
                            FAQ
                            <Flower variant="yellow" flipped />
                        </h2>

                        <p style={{ textAlign: 'center', color: 'black'}}>
                        <small>Last Update: June 1st, 2021</small>

                        </p>
                        <p style={{lineHeight: '1'}}>
                            <small style={{fontSize: '0.65em'}}>* News &amp; Updates regarding international travel restrictions to and from Italy will be sent to attendees via email and updated here for reference.</small>
                        </p>


                        <h3>Travel to Italy</h3>

                        <p style={{ textAlign: 'justify' }}>
                        To travel to Italy from the United States you will need to book a flight on
                        a "COVID-tested" flight. When you arrive in Italy on a COVID-tested
                        flight you are no longer required to quarantine as was previously the case.
                        On flights which are identified during the purchase phase as "COVID-Tested" &mdash;
                        currently offered on <strong><em>United, American, Delta, and Alitalia</em></strong> &mdash; you will have to do the following prior to boarding the plane:
                        <ol type="a">
                            <li>Complete <a style={{textDecoration: 'underline'}} href="https://app.euplf.eu/#/" rel="noreferrer noopener" target="_blank">European Digital Passenger Locator Form (dPLF)</a>, the European digital form that helps Public Health Authorities contact you in the event of exposure to SARS-CoV-2 during your trip </li>
                            <center><br />AND<br /><br /></center>
                            <li>Test negative for Covid-19 after having carried out a rapid or molecular antigen test (RT PCR) taken via swab within 48 hours of boarding. You can get tested anywhere these test types are available or at <a style={{textDecoration: 'underline'}} href="https://www.jfkairport.com/announcements/covid-19-testing" rel="noreferrer noopener" target="_blank">JFK</a> prior to departure (requires arriving at the airport earlier)</li>
                        </ol>
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        Bring your negative COVID-19 test results with you to the airport and provide them when you check in for your flight. Airline agents will be looking for a negative COVID-19 test of a type accepted by, and taken within the appropriate timeframe for, your destination. You may also need to present these documents upon arrival at your destination.
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        Upon arrival at Rome Fiumicino, you will be required to undergo a second rapid COVID antigen test (cost €20). <strong>Important! &mdash; You will need to reserve a time slot for your test prior to departing the U.S. by <a style={{textDecoration: 'underline'}} href="https://www.adr.it/en/digital-health-pass" rel="noreferrer noopener" target="_blank">registering here.</a></strong>
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        Assuming a negative result, you will then be free to travel throughout Italy!
                        </p>

                        {/* <h2>Weddings in Italy</h2> */}

                        <h3>Italy’s Wedding Green Pass</h3>

                        <p style={{ textAlign: 'justify' }}>
                        <em>Starting June 15th, weddings are permitted in Italy! Yay! </em>
                        Italy has instituted a new requirement that all wedding attendees obtain a Green Pass.
                        The Green Pass <strong>certifies that you are vaccinated</strong>, OR have recovered from COVID-19 in the
                        3 months preceding the trip, OR have a negative PCR test result 48 hours <em>before the wedding</em>.
                        If you are not vaccinated and need to be re-tested in Italy to have a valid negative test within
                        48 hours of the wedding, you can do so at various clinics (in most towns) at a cost of around €80.
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        Italy’s digital Green Pass is expected to launch mid-June; the E.U.'s digital COVID-19 certificate on July 1
                        (the E.U. certificate would only be needed if you are traveling beyond Italy).
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        As more information becomes available regarding the digital Green Pass we will provide an update via email.
                        </p>

                        <h3>Travel Back to the United States</h3>
                        <p style={{ textAlign: 'justify' }}>
                        To travel back to the United States from Italy, you will need to present proof of a negative COVID-19 test taken within 3 days before departure and sign an <a href="https://www.delta.com/us/en/travel-planning-center/know-before-you-go/attestation" target="_blank" rel="noreferrer noopener">attestation</a> confirming a negative result.
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        Tests are available at Rome Fiumicino "Leonardo da Vinci" International Airport and carried out in the following areas:​

                        <ul>
                            <li>Drive-In set up at the Long Stay car park; in this case, you need to arrive 4 hours before the departure of your flight.</li>
                            <li>"Covid-19 Test Area" Health centre in Terminal T3, Arrival Area, Door 4; in this case, you have to arrive 3.5 hours before the departure of your flight.</li>
                        </ul>
                        </p>



                        <p style={{ textAlign: 'justify' }}>
                        Download a <a href="https://www.alitalia.com/content/dam/alitalia/files/EN/20200910_area_test_covid19_routes_ENG.pdf" rel="noreferrer noopener">map</a> of Rome Fiumicino Airport with the locations of the Covid test points here
                        </p>

                        <p style={{ textAlign: 'justify' }}>
                        Please note that on or after July 1, flights from Italy to the United States will again become <strong>standard non-Covid-Tested flights</strong>. You will only be required to meet the U.S. Entry Requirements which are detailed above! For more information, please reference the <a href="https://www.cdc.gov/coronavirus/2019-ncov/travelers/testing-international-air-travelers.html" rel="noreferrer noopener">CDC website</a>.
                        </p>

                    </TravelSectionContainer>
                </Section>
            </>
        )
    }
}

export default TravelSection
