import React, { useState } from 'react'

import Nav from './components/Nav'
import TabBar from './components/TabBar'
import Section from './components/Section'

import FirstSection from './sections/FirstSection'
import LocationSection from './sections/LocationSection'
import ItinerarySection from './sections/ItinerarySection'
import TravelSection from './sections/TravelSection'
import styled, { createGlobalStyle, withTheme } from 'styled-components'
import StaySection from './sections/StaySection'
import PictureSection from './sections/PictureSection'
import RegistrySection from './sections/RegistrySection'
import PhotoSection from './sections/PictureSection'

const RSVPModal = styled.div`
    position: fixed;
    z-index: 101;
    background: white;
    width: 85%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto auto;
    max-width: 800px;
    max-height: 533px;

    @media (min-width: 1024px) {
        max-width: 800px;
        width: 100%;
        height: 800px;
        max-height: 800px;
    }
`

const RSVPiFrame = styled.iframe`
    @media (max-width: 1023px) {
        transform: scale(0.75);
        transform-origin: 0 0;
        width: 133.3333%;
        height: 710.6666666489px;
    }

    @media (min-width: 1024px) {
        max-width: 800px;
        width: 100%;
        height: 800px;
    }
`

const Backdrop = styled.div`
    background: rgba(0, 0, 0, 0.7);
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
`

const BackdropGlobalStyle = createGlobalStyle`
    html, body {
        margin: 0;
        height: 100%;
        overflow: hidden;
    }
`
const SnapScroll = styled.div``

function App(props) {
    const [isRSVPVisible, setRSVPVisible] = useState(false)

    const toggleRSVP = e => {
        const visible = !isRSVPVisible
        setRSVPVisible(visible)

        window.gtag('event', 'click', {
            event_category: 'rsvp',
            event_label: visible ? 'open' : 'close'
        })
    }

    return (
        <>
            <Nav onRSVPClick={toggleRSVP} />
            <SnapScroll>
                {/* <FirstSection /> */}
                <LocationSection theme={props.theme} onRSVPClick={toggleRSVP} />
                <PhotoSection />
                <ItinerarySection />
                <TravelSection />
                <StaySection />
                <RegistrySection />
            </SnapScroll>
            {props.theme.tabbar.enabled ? (
                <TabBar onRSVPClick={toggleRSVP} />
            ) : null}
            {isRSVPVisible ? (
                <>
                    <Backdrop onClick={toggleRSVP} />
                    <RSVPModal>
                        <RSVPiFrame
                            className="airtable-embed airtable-dynamic-height"
                            src="https://airtable.com/embed/shrJsh9nvrz1ihajC?backgroundColor=purple"
                            width="133.3333%"
                            height="710.6666666489px"
                            style={{
                                background: 'transparent',
                                border: '0px solid #fff;',
                                borderWidth: '0',
                                outline: 'none'
                            }}
                        ></RSVPiFrame>
                    </RSVPModal>
                    <BackdropGlobalStyle />
                </>
            ) : null}
        </>
    )
}

export default withTheme(App)
