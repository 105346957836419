import React from 'react'
import styled, { createGlobalStyle, withTheme } from 'styled-components'
import icons from './icons'

const TabBarGlobalStyle = createGlobalStyle`
    body {
        padding-bottom: ${props => props.theme.tabbar.height} !important;
    }
`

const TabBarContainer = styled.nav`
    position: fixed;
    z-index: 2;
    bottom: 0;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    height: ${props => props.theme.tabbar.height};
    background-color: ${props => props.theme.tabbar.backgroundColor};
    font-size: ${props => props.theme.tabbar.fontSize};
    font-family: ${props => props.theme.tabbar.fontFamily};
`

const TabBarIcon = styled.a`
    color: ${props => props.theme.tabbar.color};
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 10px;

    img {
        width: 24px;
        height: 24px;
    }
`

const TabBar = props => {
    const onClick = e => {
        const target = document.querySelector(e.currentTarget.dataset.href)

        if (target) {
            e.preventDefault()

            if (props.theme.nav.sticky) {
                const offsetNav = props.theme.nav.sticky
                    ? parseInt(props.theme.nav.height.replace('px', ''), 10)
                    : 0
                window.scroll({
                    top: target.offsetTop - offsetNav,
                    left: 0,
                    behavior: 'smooth'
                })
            } else {
                target.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            }
        }
    }

    return (
        <React.Fragment>
            <TabBarGlobalStyle />
            <TabBarContainer>
                <TabBarIcon
                    onClick={onClick}
                    href="#itinerary"
                    data-href="#itinerary"
                >
                    <img src={icons.itinerary} />
                    Itinerary
                </TabBarIcon>

                <TabBarIcon
                    onClick={onClick}
                    href="#travel"
                    data-href="#travel"
                >
                    <img src={icons.travel} />
                    Travel
                </TabBarIcon>

                <TabBarIcon
                    onClick={props.onRSVPClick}
                    href="#rsvp"
                    data-href="#rsvp"
                >
                    <img src={icons.rsvp} />
                    RSVP
                </TabBarIcon>

                <TabBarIcon onClick={onClick} href="#stay" data-href="#stay">
                    <img src={icons.stay} />
                    Stay
                </TabBarIcon>

                {/* <TabBarIcon onClick={onClick} href="#todo" data-href="#todo">
                    <img src={icons.todo} />
                    To Do
                </TabBarIcon> */}
            </TabBarContainer>
        </React.Fragment>
    )
}

export default withTheme(TabBar)
