import React, { useState } from 'react'
import styled, { css, createGlobalStyle, withTheme } from 'styled-components'
import Button from '../Button'
import logo from './logo.png'

const NavBackground = styled.header`
    position: ${props => (props.theme.nav.sticky ? 'fixed' : 'relative')};
    top: 0;
    width: 100%;
    z-index: 97;
`

const NavContents = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    height: ${props => props.theme.nav.height};
    background-color: ${props => props.theme.nav.backgroundColor};

    @media (min-width: 600px) {
        .menu-button {
            display: none;
        }
    }

    @media (max-width: 600px) {
        .nav-items {
            &.menu-closed {
                padding: 0;
                width: 100%;
                text-align: center;
                display: block;
                position: absolute;
                top: ${props => props.theme.nav.height + 'px'};
                left: 0;
                margin: 0;
                height: 0;
                z-index: 1000;
                overflow: hidden;
            }

            &.menu-open {
                border-top: 1px solid rgb(83, 104, 70);
                border-bottom: 1px solid rgb(83, 104, 70);
                background: ${props => props.theme.nav.backgroundColor};
                padding: 1em;
                width: 100%;
                text-align: center;
                display: block;
                position: absolute;
                margin: 0;
                top: ${props => props.theme.nav.height};
                left: 0;
                height: auto;

                & li {
                    padding: 1em;
                }
            }
        }
    }

    .nav-items {
        display: none;

        @media (min-width: 600px) {
            display: flex;
        }
    }

    ul {
        color: black;
        list-style-type: none;
        display: flex;
        flex-direction: row;
        padding: 0 1em;

        li {
            padding: 0 1em;
        }
    }
`

const NavContentsLeft = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;

    /* The logo needs special margin */
    margin-left: calc(1em - 7px);
`

const NavContentsRight = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 1em;

    button {
        margin-left: 1em;
    }
`

const NavGlobalSticky = createGlobalStyle`
    body {
        padding-top: ${props => props.theme.nav.height} !important;
    }
`

const NavLogo = styled.img`
    width: 150px;
    height: 51px;
    margin-top: 17px;
`

const Nav = props => {
    const [isMenuOpen, setMenuOpen] = useState(false)

    const onMenuClick = e => {
        console.log('opening menu')
        setMenuOpen(!isMenuOpen)
    }

    const onClick = e => {
        setMenuOpen(!isMenuOpen)
        const section = e.currentTarget.dataset.href
        const target = document.querySelector(section)

        window.gtag('event', 'click', {
            event_category: 'nav',
            event_label: section
        })

        if (target) {
            e.preventDefault()

            if (props.theme.nav.sticky) {
                const offsetNav = props.theme.nav.sticky
                    ? parseInt(props.theme.nav.height.replace('px', ''), 10)
                    : 0
                window.scroll({
                    top: target.offsetTop - offsetNav,
                    left: 0,
                    behavior: 'smooth'
                })
            } else {
                target.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            }
        }
    }

    return (
        <React.Fragment>
            <NavBackground>
                <NavContents>
                    <NavContentsLeft>
                        <a href="/">
                            <NavLogo
                                src={logo}
                                width={150}
                                height={51}
                                onClick={e => {
                                    window.gtag('event', 'click', {
                                        event_category: 'nav',
                                        event_label: 'homepage'
                                    })
                                }}
                            />
                        </a>
                    </NavContentsLeft>
                    <NavContentsRight>
                        <ul
                            className={`nav-items ${
                                isMenuOpen ? 'menu-open' : 'menu-closed'
                            }`}
                        >
                            <li>
                                <a
                                    href="#itinerary"
                                    data-href="#itinerary"
                                    onClick={onClick}
                                >
                                    Itinerary
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#travel"
                                    data-href="#travel"
                                    onClick={onClick}
                                >
                                    La Pescaia
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#stay"
                                    data-href="#stay"
                                    onClick={onClick}
                                >
                                    Stay
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#registry"
                                    data-href="#registry"
                                    onClick={onClick}
                                >
                                    Registry
                                </a>
                            </li>
                            <li>
                                <a
                                    href="#faq"
                                    data-href="#faq"
                                    onClick={onClick}
                                >
                                    F.A.Q.
                                </a>
                            </li>
                        </ul>
                        {/* <Button text="RSVP" onClick={props.onRSVPClick} /> */}
                        <div className="menu-button">
                            <Button text="Menu" onClick={onMenuClick}></Button>
                        </div>
                    </NavContentsRight>
                </NavContents>
            </NavBackground>
            {props.theme.nav.sticky ? <NavGlobalSticky /> : null}
        </React.Fragment>
    )
}

export default withTheme(Nav)
