import useResizer from './useResizer'

import photo1 from './photos/1.jpeg'
import photo2 from './photos/2.jpeg'
import photo3 from './photos/3.jpeg'
import photo4 from './photos/4.jpeg'
import photo5 from './photos/5.jpeg'
import photo6 from './photos/6.jpeg'
import photo7 from './photos/7.jpeg'
import photo8 from './photos/8.jpeg'
import photo9 from './photos/9.jpeg'
import photo10 from './photos/10.jpeg'
import photo11 from './photos/11.jpeg'
import photo12 from './photos/12.jpeg'
import photo13 from './photos/13.jpeg'
import photo14 from './photos/14.jpeg'
import photo15 from './photos/15.jpeg'
import photo16 from './photos/16.jpeg'
import photo17 from './photos/17.jpeg'
import photo18 from './photos/18.jpeg'
import photo19 from './photos/19.jpeg'
import photo20 from './photos/20.jpeg'
import photo21 from './photos/21.jpeg'
import photo22 from './photos/22.jpeg'
import photo23 from './photos/23.jpeg'
import photo24 from './photos/24.jpeg'
import photo25 from './photos/25.jpeg'
import photo26 from './photos/26.jpeg'
import photo27 from './photos/27.jpeg'
import photo28 from './photos/28.jpeg'
import photo29 from './photos/29.jpeg'
import photo30 from './photos/30.jpeg'
import photo31 from './photos/31.jpeg'
import photo32 from './photos/32.jpeg'

import React, { useState, useCallback } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import styled from 'styled-components'
import Gallery from 'react-photo-gallery'

import Section from '../../components/Section'

const PhotoSectionContainer = styled.div`
    h3 {
        margin-bottom: 0;
    }

    h3 + p {
        margin-top: 0;
    }

    p {
        max-width: 500px;
        margin: auto;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
`

const photos = [
    {
        src: photo15,
        width: 1536,
        height: 2048
    },

    {
        src: photo22,
        width: 849,
        height: 849
    },
    {
        src: photo23,
        width: 1532,
        height: 2050
    },
    // {
    //     src: photo3,
    //     width: 2049,
    //     height: 1537
    // },
    // {
    //     src: photo4,
    //     width: 2049,
    //     height: 1537
    // },
    // {
    //     src: photo6,
    //     width: 1932,
    //     height: 1932
    // },
    {
        src: photo7,
        width: 1538,
        height: 2048
    },
    // {
    //     src: photo8,
    //     width: 1537,
    //     height: 2049
    // },
    {
        src: photo9,
        width: 640,
        height: 428
    },
    {
        src: photo10,
        width: 1536,
        height: 2051
    },
    {
        src: photo11,
        width: 1539,
        height: 2048
    },
    // {
    //     src: photo12,
    //     width: 1536,
    //     height: 2048
    // },

    {
        src: photo13,
        width: 672,
        height: 504
    },
    {
        src: photo5,
        width: 960,
        height: 960
    },
    // {
    //     src: photo14,
    //     width: 960,
    //     height: 720
    // },
    {
        src: photo1,
        width: 1280,
        height: 960
    },
    // {
    //     src: photo16,
    //     width: 1024,
    //     height: 683
    // },
    {
        src: photo17,
        width: 1536,
        height: 2048
    },
    {
        src: photo18,
        width: 1536,
        height: 2048
    },
    {
        src: photo19,
        width: 1930,
        height: 1450
    },
    {
        src: photo20,
        width: 1532,
        height: 2038
    },
    {
        src: photo2,
        width: 2049,
        height: 1537
    },
    {
        src: photo21,
        width: 1536,
        height: 2048
    },
    {
        src: photo24,
        width: 1536,
        height: 2048
    },
    {
        src: photo25,
        width: 403,
        height: 604
    },
    {
        src: photo26,
        width: 403,
        height: 604
    },
    {
        src: photo28,
        width: 1536,
        height: 2048
    },
    {
        src: photo29,
        width: 2049,
        height: 1537
    },
    {
        src: photo30,
        width: 1539,
        height: 2048
    },
    {
        src: photo31,
        width: 2731,
        height: 1537
    },
    {
        src: photo32,
        width: 2049,
        height: 1537
    }
]

const PhotoSection = props => {
    const isMobile = useResizer()
    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, [])

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }

    const columns = containerWidth => {
        let columns = 1
        if (containerWidth >= 500) columns = 2
        if (containerWidth >= 900) columns = 10
        if (containerWidth >= 1500) columns = 10
        return columns
    }

    return (
        <Section id="Photo" disableHeight>
            <PhotoSectionContainer>
                <Gallery
                    photos={isMobile ? photos.slice(0, 5) : photos}
                    onClick={openLightbox}
                    direction="row"
                    columns={columns}
                    margin={2}
                />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </PhotoSectionContainer>
        </Section>
    )
}

export default PhotoSection
