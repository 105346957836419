import React from 'react'
import styled from 'styled-components'

import Section from '../../components/Section'
import pescaia from '../LocationSection/pescaia.svg'
import image from './image.png'

const LocationSectionContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    flex: 0;
    height: calc(100% - 4em);
    margin: auto auto;
    top: ${props => props.theme.nav.height};
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    img {
        margin-bottom: 1em;
        width: 150px;
        height: 100px;
    }

    h3 {
        margin-bottom: 0;
    }

    h3 + p {
        margin-top: 0;
    }

    @media (min-width: 1024px) {
        font-size: 24px;

        img {
            width: 170.25px;
            height: 146.25px;
        }
    }
`

const LocationTwoByContainer = styled.div`
    display: flex;
    flex-direction: column;

    div {
        margin-bottom: 1em;
    }

    @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: center;

        margin-top: 3em;

        div {
            width: 25%;
            margin-bottom: 0;
        }
    }
`

const GettingMarried = styled.h2`
    line-height: 1.25;
`

const Image = styled.div`
    transform: translateZ(0);

    position: absolute;
    background-image: url(${props => props.src});
    width: 100%;
    height: 100%;
    max-width: 100%;

    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    min-height: calc(
        100vh -
            ${props =>
                props.theme.nav.sticky
                    ? props.theme.nav.height
                    : props.theme.nav.height} -
            ${props =>
                props.theme.tabbar.enabled ? props.theme.tabbar.height : '0px'}
    );
    max-height: calc(
        100vh -
            ${props =>
                props.theme.nav.sticky
                    ? props.theme.nav.height
                    : props.theme.nav.height} -
            ${props =>
                props.theme.tabbar.enabled ? props.theme.tabbar.height : '0px'}
    );
`

const CustomSection = styled.div`
    min-height: calc(
        100vh -
            ${props =>
                props.theme.nav.sticky
                    ? props.theme.nav.height
                    : props.theme.nav.height} -
            ${props =>
                props.theme.tabbar.enabled ? props.theme.tabbar.height : '0px'}
    );
    max-height: calc(
        100vh -
            ${props =>
                props.theme.nav.sticky
                    ? props.theme.nav.height
                    : props.theme.nav.height} -
            ${props =>
                props.theme.tabbar.enabled ? props.theme.tabbar.height : '0px'}
    );
`

const FirstSection = () => {
    return (
        <CustomSection collapse>
            {/* <Image src={image} /> */}
            <Image
                src={
                    'https://images.squarespace-cdn.com/content/v1/5d095f1ce26d080001497b5b/1560896166574-I9QZGDXFKLFSKYMX5BZL/ke17ZwdGBToddI8pDm48kPHuXXQ5aXkivCq6BeeGHLR7gQa3H78H3Y0txjaiv_0fDoOvxcdMmMKkDsyUqMSsMWxHk725yiiHCCLfrh8O1z5QPOohDIaIeljMHgDF5CVlOqpeNLcJ80NK65_fV7S1UaRiPhSzHnqInJjzxHCnpvhE6X8TF1jsOuwJE3GOLjZBS9mayiR-vA71CXrLbFBG1g/La+Pescaia+1.jpg?format=2500w'
                }
            />

            {/* <LocationSectionContainer>
                <div
                    style={{
                        fontSize: '0.8em',
                        backgroundColor: '#FBFAF7',
                        padding: '3em 3em 1.2em 3em',
                        border: '4px solid rgba(255, 255, 255, 0.5)'
                    }}
                >
                    <img src={pescaia} width={75} height={65} />
                    <GettingMarried>We're getting married!</GettingMarried>

                    <LocationTwoByContainer>
                        <div>
                            <h3>where</h3>
                            <p>
                                La Pescaia Resort
                                <br />
                                Sticciano GR / Italy
                            </p>
                        </div>

                        <div>
                            <h3>when</h3>
                            <p>
                                Saturday, the thirteenth of June
                                <br />
                                Two thousand twenty
                                <br />
                                At six o’ click in the evening
                            </p>
                        </div>
                    </LocationTwoByContainer>
                </div>
            </LocationSectionContainer> */}
        </CustomSection>
    )
}

const StyledFirstSection = styled(FirstSection)`
    min-height: calc(
        100vh -
            ${props =>
                props.theme.nav.sticky
                    ? props.theme.nav.height
                    : props.theme.nav.height} -
            ${props =>
                props.theme.tabbar.enabled ? props.theme.tabbar.height : '0px'}
    );
    max-height: calc(
        100vh -
            ${props =>
                props.theme.nav.sticky
                    ? props.theme.nav.height
                    : props.theme.nav.height} -
            ${props =>
                props.theme.tabbar.enabled ? props.theme.tabbar.height : '0px'}
    );
`

export default StyledFirstSection
