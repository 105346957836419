import itinerary from './itinerary.svg'
import todo from './todo.svg'
import rsvp from './rsvp.svg'
import travel from './travel.svg'
import stay from './stay.svg'
export default {
    itinerary,
    todo,
    rsvp,
    travel,
    stay
}
