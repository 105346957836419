import React from 'react'
import styled from 'styled-components'

import Section from '../../components/Section'
import Flower from '../../components/Flower'

const ItinerarySectionContainer = styled.div`
    h3 {
        margin-bottom: 0;
    }

    h3 + p {
        margin-top: 0;
    }

    hr {
        height: 1px;
        border: none;
        background: ${props => props.theme.text.body.color};
        max-width: 300px;
    }

    p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
`

const Event = styled.div`
    min-height: 200px;
    display: flex;
    flex-direction: column;
    padding: 2em 0;
`

const ItineraryBlurbContainer = styled.div`
    margin-bottom: 2.5em;
`

const ItinerarySection = props => {
    return (
        <Section id="itinerary" centered>
            <ItinerarySectionContainer>
                <ItineraryBlurbContainer>
                    <h2>
                        <Flower variant="leaves" />
                        Itinerary
                        <Flower variant="leaves" flipped />
                    </h2>
                    <p style={{ textAlign: 'justify' }}>
                        In addition to the wedding, we have arranged a couple of
                        other events to enjoy each other's company! To kick off
                        the weekend, we'd love our guests to join us at the
                        welcome dinner hosted by Joe and Teri Higgins.
                    </p>
                    <p style={{ textAlign: 'justify' }}>
                        To relax <i>after</i> the wedding, we've planned a
                        Brunch &amp; Pool Party. Apart from those, you will be
                        free to roam the Tuscan countryside or simply relax at
                        the Resort. Please take note of the schedule below.
                    </p>
                </ItineraryBlurbContainer>

                <hr />

                <Event>
                    <span>Monday, September 27th</span>
                    <h3>Welcome Dinner</h3>
                    <p>
                        Hosted by Joe and Teri Higgins
                        <br />
                        <a href="https://g.page/VALDONICAwinerytuscany?share">
                            Valdonica Winery
                        </a>
                        <br />
                        Dressy Casual
                        <br />
                        7:30pm
                    </p>
                </Event>

                <hr />

                <Event>
                    <span>Tuesday, September 28th</span>
                    <h3>Wedding!</h3>
                    <p>
                        <a href="https://goo.gl/maps/EFJmTXsvngBZCUkU6">
                            La Pescaia
                        </a>
                        <br />
                        5:30pm
                    </p>

                    <h3>Reception</h3>
                    <p>
                        <a href="https://goo.gl/maps/EFJmTXsvngBZCUkU6">
                            La Pescaia
                        </a>
                        <br />
                        6:30pm to late
                    </p>
                </Event>

                <hr />

                <Event>
                    <span>Wednesday, September 29th</span>
                    <h3>Brunch &amp; Pool Party</h3>
                    <p>
                        <a href="https://goo.gl/maps/EFJmTXsvngBZCUkU6">
                            La Pescaia
                        </a>
                        <br />
                        Bathing Suits &amp; Towels
                        <br />
                        from 12:00pm
                    </p>
                </Event>

                {/* <hr /> */}
            </ItinerarySectionContainer>
        </Section>
    )
}

export default ItinerarySection
