import React from 'react'
import { InView } from 'react-intersection-observer'

import styled, { css } from 'styled-components'

const center = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;

    & :first-child {
        margin-top: 0;
    }

    & :last-child {
        margin-bottom: 0;
    }
`

const SectionContainer = styled.div`
    width: 100%;
    position: relative;

    max-width: 100%;

    min-height: calc(
        100vh -
            ${props =>
                props.theme.nav.sticky ? props.theme.nav.height : '0px'}
    );

    ${props => (props.disableHeight ? 'min-height: 0;' : null)}

    ${props => (props.centered ? center : null)}
`

const SectionContent = styled.div`
    width: 100%;
    padding: ${props => (props.collapse ? '0' : '2em')};

    @media (min-width: 1024px) {
        padding: ${props => (props.collapse ? '0' : '3em')};
    }
`

const Section = React.forwardRef((props, ref) => {
    const { disableHeight, collapse, centered, id } = props
    return (
        <SectionContainer
            ref={ref}
            collapse={collapse}
            centered={centered}
            id={props.id}
            disableHeight={disableHeight}
        >
            <SectionContent collapse={collapse}>
                {props.children}
            </SectionContent>
        </SectionContainer>
    )
});

Section.defaultProps = {
    collapse: false,
    centered: false
}

export default Section
