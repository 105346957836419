import React, { useState, useCallback } from 'react'
import Carousel, { Modal, ModalGateway } from 'react-images'
import styled from 'styled-components'
import Gallery from 'react-photo-gallery'

import Section from '../../components/Section'
import Flower from '../../components/Flower'
import photo1 from './images/looking-around-la-pescaia-banner-1eecc1b3-8667-442f-b508-dae8a3ed9b93.jpg'
import photo2 from './images/image-asset.jpg'
import photo3 from './images/170488568.jpg'
import photo4 from './images/c59f25_507dd5519b9844c7accb77d6ebb58e2a_mv2_d_2953_2100_s_2.jpg'
import photo5 from './images/PescaiaResort_172-copia.jpg'
import photo6 from './images/looking-around-la-pescaia-3-a0b1aaea-5f4c-4f92-a8eb-7cca66834b49.jpg'
import photo7 from './images/Wedding-Putto.jpg'
import photo8 from './images/PescaiaResort_204-copia.jpg'
import photo9 from './images/La+Pescaia+1.jpg'
import photo10 from './images/1563280603.jpg'
import photo11 from './images/71769714.jpg'
import photo12 from './images/Wedding-Chiesa-1.jpg'
import photo13 from './images/PescaiaResort_074-copia.jpg'
import photo14 from './images/PescaiaResort_132-copia.jpg'
import photo15 from './images/PescaiaResort_115-copia.jpg'

const StaySectionContainer = styled.div`
    h3 {
        margin-bottom: 0;
    }

    h3 + p {
        margin-top: 0;
    }

    p {
        max-width: 500px;
        margin: auto;
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
`

const photos = [
    {
        src: photo1,
        title: 'Hello World!',
        width: 1200,
        height: 700
    },
    {
        src: photo2,
        width: 1000,
        height: 1333
    },
    {
        src: photo3,
        width: 1280,
        height: 853
    },
    {
        src: photo4,
        width: 2953,
        height: 2100
    },
    {
        src: photo5,
        width: 1024,
        height: 646
    },
    {
        src: photo6,
        width: 1998,
        height: 487
    },
    {
        src: photo7,
        width: 1024,
        height: 683
    },
    {
        src: photo8,
        width: 1600,
        height: 1066
    },
    {
        src: photo9,
        width: 1440,
        height: 960
    },
    {
        src: photo10,
        width: 1280,
        height: 853
    },
    {
        src: photo11,
        width: 1024,
        height: 768
    },
    {
        src: photo12,
        width: 1024,
        height: 694
    },
    {
        src: photo13,
        width: 1024,
        height: 694
    },
    {
        src: photo14,
        width: 1024,
        height: 683
    },
    {
        src: photo15,
        width: 1024,
        height: 683
    }
]

const StaySection = props => {
    const [currentImage, setCurrentImage] = useState(0)
    const [viewerIsOpen, setViewerIsOpen] = useState(false)

    const openLightbox = useCallback((event, { photo, index }) => {
        setCurrentImage(index)
        setViewerIsOpen(true)
    }, [])

    const closeLightbox = () => {
        setCurrentImage(0)
        setViewerIsOpen(false)
    }

    const columns = containerWidth => {
        let columns = 1
        if (containerWidth >= 500) columns = 2
        if (containerWidth >= 900) columns = 3
        if (containerWidth >= 1500) columns = 5
        return columns
    }

    return (
        <Section id="stay" centered>
            <StaySectionContainer>
                <h2>
                    <Flower variant="orange" />
                    Accommodations
                    <Flower variant="orange" flipped />
                </h2>
                <p style={{ textAlign: 'justify', marginBottom: '32px' }}>
                    For help with nearby accommodations, send a note to the MOB,
                    Nancy Albanese, at nancymalbanese@gmail.com
                </p>

                <h2>
                    <Flower variant="orange" />
                    La Pescaia
                    <Flower variant="orange" flipped />
                </h2>
                <Gallery
                    photos={photos}
                    onClick={openLightbox}
                    direction="row"
                    columns={columns}
                    margin={2}
                />
                <ModalGateway>
                    {viewerIsOpen ? (
                        <Modal onClose={closeLightbox}>
                            <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                    ...x,
                                    srcset: x.srcSet,
                                    caption: x.title
                                }))}
                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
            </StaySectionContainer>
        </Section>
    )
}

export default StaySection
