import React from 'react'
import styled from 'styled-components'
import yellow from '../../icons/flowers/yellow-flower.svg'
import blue from '../../icons/flowers/blue-flower.svg'
import olive from '../../icons/flowers/olive.svg'
import leaves from '../../icons/flowers/leaves.svg'
import orange from '../../icons/flowers/orange-flower.svg'
import orange2 from '../../icons/flowers/orange-flower-2.svg'

const FlowerImage = styled.img`
    max-width: 24px;
    margin: 0 12px
    height: 1em;
    justify-self: center;

    transform: ${props => (props.flipped ? 'scaleX(-1)' : 'scaleX(1)')};

    @media (min-width: 1024px) {
        max-width: 40px;
        margin: 0 1em;
    }
`

const Flower = props => {
    let image = null

    switch (props.variant) {
        case 'yellow':
            image = yellow
            break
        case 'blue':
            image = blue
            break
        case 'leaves':
            image = leaves
            break
        case 'orange':
            image = orange
            break
        case 'orange2':
            image = orange2
            break
        case 'olive':
            image = olive
            break
        default:
            image = blue
    }

    return <FlowerImage src={image} {...props} />
}

const StyledFlower = styled(Flower)`
    display: none;
`

export default StyledFlower
