import React from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.button`
    cursor: pointer;
    /* container */
    padding: ${props => props.theme.button[props.variant].container.padding};
    appearance: none;
    background-color: ${props =>
        props.theme.button[props.variant].container.backgroundColor};
    border-color: ${props =>
        props.theme.button[props.variant].container.borderColor};
    border-width: ${props =>
        props.theme.button[props.variant].container.borderWidth};
        ${props => props.width ? `width: ${props.width};` : null}

    a {
        color: ${props => props.theme.button[props.variant].text.color};
        font-family: ${props => props.theme.button[props.variant].text.fontFamily};
        font-size: ${props => props.theme.button[props.variant].text.fontSize};
        line-height: ${props => props.theme.button[props.variant].text.lineHeight};
    }
`
const ButtonText = styled.span`
    /* text */
    color: ${props => props.theme.button[props.variant].text.color};
    font-family: ${props => props.theme.button[props.variant].text.fontFamily};
    font-size: ${props => props.theme.button[props.variant].text.fontSize};
    line-height: ${props => props.theme.button[props.variant].text.lineHeight};
`

const Button = props => {
    const { text, variant, children, onClick, width } = props
    return (
        <ButtonContainer variant={variant} onClick={onClick} width={width}>
            {text ? (
                <ButtonText variant={variant}>{text}</ButtonText>
            ) : (
                children
            )}
        </ButtonContainer>
    )
}

Button.defaultProps = {
    variant: 'small',
    text: null
}

export default Button
