const theme = {
    nav: {
        sticky: true,
        backgroundColor: '#FBFAF7',
        height: '77px'
    },
    tabbar: {
        enabled: false,
        backgroundColor: '#fff',
        color: '#000',
        height: '60px',
        fontFamily: 'Playfair Display',
        fontSize: '12px'
    },
    button: {
        small: {
            text: {
                fontFamily: 'Crimson Text',
                fontSize: '12px',
                lineHeight: '1',
                color: '#000'
            },
            container: {
                borderWidth: '1px',
                borderRadius: '0',
                borderColor: '#000',
                backgroundColor: '#fff',
                padding: '8px 16px'
            }
        },
        medium: {
            text: {
                fontFamily: 'Crimson Text',
                fontSize: '24px',
                lineHeight: '1',
                color: '#000'
            },
            container: {
                borderWidth: '1px',
                borderRadius: '0',
                borderColor: '#000',
                backgroundColor: '#fff',
                padding: '16px 32px'
            }
        }
    },
    text: {
        header: {
            fontFamily: 'Beth Ellen, serif',
            fontWeight: 'normal',
            color: 'rgb(83, 104, 70)'
        },
        body: {
            fontFamily: 'Crimson Text, sans-serif',
            color: 'rgb(83, 104, 70)'
        }
    },
    colors: {
        backgroundColor: '#FBFAF7'
    }
}

export default theme
