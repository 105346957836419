import React, { useEffect } from 'react'
import styled from 'styled-components'

import Section from '../../components/Section'
import Flower from '../../components/Flower'

const RegistrySectionContainer = styled.div`
    h3 {
        margin-bottom: 0;
    }

    h3 + p {
        margin-top: 0;
    }

    hr {
        height: 1px;
        border: none;
        background: ${props => props.theme.text.body.color};
        max-width: 300px;
    }

    p {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
    }

    .button {
        display: inline-block;
        width: auto;
        cursor: pointer;
        /* container */
        padding: ${props => props.theme.button['medium'].container.padding};
        appearance: none;
        background-color: ${props =>
            props.theme.button['medium'].container.backgroundColor};
        border-color: ${props =>
            props.theme.button['medium'].container.borderColor};
        border-style: solid;
        border-width: ${props =>
            props.theme.button['medium'].container.borderWidth};
        /* text */
        color: ${props => props.theme.button['medium'].text.color};
        font-family: ${props => props.theme.button['medium'].text.fontFamily};
        font-size: ${props => props.theme.button['medium'].text.fontSize};
        line-height: ${props => props.theme.button['medium'].text.lineHeight};
    }

    @media (min-width: 1024px) {
        font-size: 24px;
    }
`

const Event = styled.div`
    min-height: 200px;
    display: flex;
    flex-direction: column;
    padding: 2em 0;
`

const RegistryBlurbContainer = styled.div`
    margin-bottom: 2.5em;
`

const RegistrySection = props => {
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        // Update the document title using the browser API
        // const zolaWidget = function(e, t, n) {
        //     let s
        //     let a = e.getElementsByTagName(t)[0]
        //     e.getElementById(n) ||
        //         ((s = e.createElement(t)),
        //         (s.id = n),
        //         (s.async = !0),
        //         (s.src = ''),
        //         a.parentNode.insertBefore(s, a))
        // }
        // zolaWidget(document, 'script', 'zola-wjs')
    })

    return (
        <Section id="registry" centered>
            <RegistrySectionContainer>
                <RegistryBlurbContainer>
                    <h2>
                        <Flower variant="leaves" />
                        Registry
                        <Flower variant="leaves" flipped />
                    </h2>
                    <p style={{ textAlign: 'center' }}>
                        Please know that your attendance at our wedding and
                        spending time with us is the most amazing gift and all
                        that we could hope for.
                    </p>
                    <p style={{ textAlign: 'center' }}>
                        <a
                            className="button"
                            href="https://www.zola.com/registry/claireandjoseph2020"
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={e => {
                                window.gtag('event', 'click', {
                                    event_category: 'registry'
                                })
                            }}
                        >
                            View Registry on Zola
                        </a>
                    </p>
                </RegistryBlurbContainer>
            </RegistrySectionContainer>
        </Section>
    )
}

export default RegistrySection
