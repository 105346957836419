import React, { useState } from 'react'
import styled from 'styled-components'
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import { InView } from 'react-intersection-observer'

import Section from '../../components/Section'
import Button from '../../components/Button'
import pescaia from './pescaia.svg'
import shell from '../../icons/flowers/shell.png'

import bgLeft from '../../icons/flowers/bg-left.svg'
import bgRight from '../../icons/flowers/bg-right.svg'

const LocationSectionContainer = styled.div`
    .pescaia-image {
        margin-bottom: 1em;
        width: 150px;
        height: 100px;
    }

    h3 {
        margin-bottom: 0;
    }

    h3 + p {
        margin-top: 0;
    }

    .bg-left,
    .bg-right {
        position: absolute;
        left: 0;
        width: 20%;
        z-index: 1;
    }

    .bg-right {
        right: 0;
        left: auto;
        top: 200px;
    }

    @media (min-width: 1024px) {
        font-size: 24px;

        .pescaia-image {
            width: 170.25px;
            height: 146.25px;
        }
    }
`

const LocationTwoByContainer = styled.div`
    display: flex;
    flex-direction: column;

    div {
        margin-bottom: 1em;
    }

    @media (min-width: 1024px) {
        flex-direction: row;
        justify-content: center;

        margin-top: 3em;

        div {
            width: 25%;
            margin-bottom: 0;
        }
    }
`

const CustomConfetti = styled(Confetti)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
    z-index: 0;
`

const GettingMarried = styled.h2`
    line-height: 1.25;
    color: rgb(83, 104, 70);
`

const LocationSection = props => {
    const { width, height } = useWindowSize()
    const [isConfettiRunning, setConfettiRunning] = useState(false)

    const onClick = e => {
        // setMenuOpen(!isMenuOpen)
        const section = e.currentTarget.dataset.href
        const target = document.querySelector(section)

        window.gtag('event', 'click', {
            event_category: 'nav',
            event_label: section
        })

        if (target) {
            e.preventDefault()

            if (props.theme.nav.sticky) {
                const offsetNav = props.theme.nav.sticky
                    ? parseInt(props.theme.nav.height.replace('px', ''), 10)
                    : 0
                window.scroll({
                    top: target.offsetTop - offsetNav,
                    left: 0,
                    behavior: 'smooth'
                })
            } else {
                target.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                })
            }
        }
    }

    return (
        <>
            <Section id="location" centered>
                {isConfettiRunning ? (
                    <CustomConfetti
                        width={width}
                        height={height}
                        numberOfPieces={100}
                        recycle={false}
                        gravity={0.4}
                        run={isConfettiRunning}
                        wind={0}
                        colors={['#C98D59', '#F6D84F', '#5E8D54', '#D68FA5']}
                    />
                ) : null}
                <InView
                    as="div"
                    onChange={(inView, entry) => {
                        if (inView) {
                            setConfettiRunning(true)
                        }
                    }}
                    threshold={1}
                    triggerOnce={true}
                >
                    <LocationSectionContainer>
                        {/* <img className="bg-left" src={bgLeft} />
                        <img className="bg-right" src={bgRight} /> */}
                        {/* <img
                            className="pescaia-image"
                            src={pescaia}
                            width={75}
                            height={65}
                        /> */}
                        <img src={shell} width={250} height={'236.078px'} />
                        <GettingMarried>
                            Claire &amp; Joe <br /> are getting married!
                        </GettingMarried>

                        <LocationTwoByContainer>
                            <div>
                                <h3>where</h3>
                                <p>
                                    <a href="https://goo.gl/maps/EFJmTXsvngBZCUkU6">
                                        La Pescaia
                                    </a>
                                    <br />
                                    Sticciano Scalo, Italy
                                </p>
                            </div>

                            <div>
                                <h3>when</h3>
                                <p>
                                    Tuesday, September 28th, 2021
                                    <br />
                                    5:30pm
                                </p>
                            </div>
                        </LocationTwoByContainer>
                        {/* <br />
                        <br />
                        <Button
                            className="button button-location"
                            variant="medium"
                            width="175px"
                            text="~ RSVP ~"
                            onClick={props.onRSVPClick}
                        /> */}

                        <br />
                        <br />

                        </LocationSectionContainer>
                        <LocationSectionContainer>
                        <a href="#faq"
                        data-href="#faq" onClick={onClick}>
                            <Button
                            className="button button-location"
                            variant="medium"
                            width="250px"
                            text="Travel F.A.Q."
                        ></Button></a>

                        <br /><br />

                        <a
                        href="https://www.zola.com/registry/claireandjoseph2020"
                        rel="noopener noreferrer"
                        target="_blank"
                        onClick={e => {
                            window.gtag('event', 'click', {
                                event_category: 'registry'
                            })
                        }}
                        ><Button
                            variant="medium"
                            className="button button-location"
                            width="250px"

                            text="Registry" /></a>
                    </LocationSectionContainer>
                </InView>
            </Section>
        </>
    )
}

export default LocationSection
